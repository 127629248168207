<template>
  <div class="text-center d-flex justify-content-center align-items-center">
    <div class="text-muted">
      <h1>
        <font-awesome-icon icon="question-circle" size="2x" />
      </h1>
      <h1>Page Not Found</h1>

      <br />
      <b-button to="/" variant="primary">Vendor Connect Home</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped lang="scss">
div.d-flex {
  height: 100vh;
}
</style>